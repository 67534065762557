import React, { ReactElement } from "react";
import "./Checkbox.css";

type CheckboxProps = {
  checked: boolean;
  label: string;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Checkbox(
  props: CheckboxProps
): ReactElement<CheckboxProps> {
  return (
    <label className="checkbox-component-label">
      <input
        type="checkbox"
        onChange={() => {
          props.setChecked(!props.checked);
        }}
      />
      <svg
        className={`checkbox ${props.checked ? "checkbox--active" : ""}`}
        aria-hidden="true"
        viewBox="0 0 15 11"
        fill="none"
      >
        <path
          d="M1 4.5L5 9L14 1"
          strokeWidth="1.5"
          stroke={props.checked ? "#84edc1" : "none"}
        />
      </svg>
      {props.label}
    </label>
  );
}

import React from "react";
import "./FilePreviewElement.css";
import xIcon from "../assets/x-icon.png";
import openIcon from "../assets/open-icon.png";
import { storage } from "../firebase";

type FilePreviewElementProps = {
  viewingMode: boolean;
  url?: string;
  name: string;
  setUploadedFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedFiles?: File[];
};

const FilePreviewElement = (props: FilePreviewElementProps) => {
  const cancelFile = () => {
    if (props.setUploadedFiles && props.uploadedFiles) {
      props.setUploadedFiles(
        props.uploadedFiles.filter(
          (uploadedFile) => uploadedFile.name !== props.name
        )
      );
    }
  };

  const openFile = () => {
    if (props.url) {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = props.url;
      a.click();
      storage.refFromURL(props.url).delete().catch(console.error);
    }
  };

  return (
    <div
      className="fpreview-container"
      onClick={props.viewingMode ? openFile : cancelFile}
    >
      <p className="fpreview-name-text">{props.name}</p>
      <img
        alt="file-preview-action-icon"
        src={props.viewingMode ? openIcon : xIcon}
        className={"fpreview-cancel-button-" + props.viewingMode}
        onClick={props.viewingMode ? openFile : cancelFile}
      />
    </div>
  );
};

export default FilePreviewElement;

import React, { useEffect, useState } from "react";
import "./LinkNotFoundPage.css";

const LinkNotFoundPage = () => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setRender(true);
    }, 700);
  }, []);

  return (
    <div className="not-found">
      <div className="not-found-link">
        <div className={`not-found-container-${render}`}>
          <h1 className="not-found-title">The message has been deleted</h1>
          <h3 className="not-found-subtitle">
            The message requested to view has been permanently deleted from the
            system.
          </h3>
          <button
            className="not-found-button"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <p className="not-found-button-text">Back to Home</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkNotFoundPage;

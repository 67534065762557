import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GenerateLinkPage from "./pages/GenerateLinkPage";
import ViewLinkPage from "./pages/ViewLinkPage";
import LinkNotFoundPage from "./pages/LinkNotFoundPage";
import UsagePage from "./pages/UsagePage";
import AboutPage from "./pages/AboutPage";
import { useEffect, useState } from "react";
import { db, auth, storage } from "./firebase";
import Navbar from "./components/Navbar";
import emailjs from "emailjs-com";

function App() {
  const [activeLinks, setActiveLinks] = useState<string[]>([]);
  const [active, setActive] = useState(false); // for hamburger menu

  const fetchActiveLinks = async (): Promise<string[]> => {
    try {
      return await db
        .collection("activeLinks")
        .get()
        .then((querySnapshot) => {
          const tempActiveLinks: string[] = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().expireDate !== null) {
              const expirydate = doc.data().expireDate.toDate();
              if (expirydate > new Date()) {
                tempActiveLinks.push(doc.id);
              } else {
                let templateParams = {
                  to_email: doc.data().notify || undefined,
                  time:
                    new Date().toLocaleString() +
                    ", " +
                    Intl.DateTimeFormat()
                      .resolvedOptions()
                      .timeZone.replace("_", " ") +
                    " timezone",
                  message: "Your Privalink has recently expired at",
                };
                doc.data().files.forEach((file_url: string) => {
                  storage.refFromURL(file_url).delete().catch(console.error);
                });
                db.collection("activeLinks")
                  .doc(doc.id)
                  .delete()
                  .then(() => {
                    if (
                      templateParams.to_email !== "" &&
                      templateParams.to_email !== undefined
                    ) {
                      emailjs
                        .send(
                          "service_matmpqc",
                          "template_jb0dwaj",
                          templateParams,
                          "user_XvdZN1t0s9N6EJ0D07jFO"
                        )
                        .then(
                          (result) => {
                            console.log(result.text);
                          },
                          (error) => {
                            console.log(error.text);
                          }
                        );
                    }
                  });
              }
            } else {
              tempActiveLinks.push(doc.id);
            }
          });
          return tempActiveLinks;
        });
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    // sign in anonymously
    auth
      .signInAnonymously()
      .then(() => {
        (async () => {
          const activeLinks = await fetchActiveLinks();
          setActiveLinks(activeLinks);
        })();
      })
      .catch(console.error);
  }, []);

  return (
    <Router>
      <Navbar active={active} setActive={setActive} />
      <main>
        <Switch>
          <Route exact path="/" component={GenerateLinkPage} />
          <Route exact path="/security" component={UsagePage} />
          <Route exact path="/about" component={AboutPage} />
          {activeLinks.map((docID) => (
            <Route
              exact
              key={docID}
              path={"/" + docID}
              component={ViewLinkPage}
            />
          ))}
          <Route path="*" component={LinkNotFoundPage} />
        </Switch>
      </main>
    </Router>
  );
}

export default App;

import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
console.warn = () => {};

const firebaseConfig = {
  apiKey: "AIzaSyA6PtpHFe2RESuFcHuJWnVuR35FpBQstV4",
  authDomain: "cryptic-notes-1025e.firebaseapp.com",
  projectId: "cryptic-notes-1025e",
  storageBucket: "cryptic-notes-1025e.appspot.com",
  messagingSenderId: "194472970216",
  appId: "1:194472970216:web:9529481ae24f1bea76865d",
};

let app;

if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}

const db = app.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };

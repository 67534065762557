import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./ViewLinkPage.css";
import { db } from "../firebase";
import FilePreviewElement from "../components/FilePreviewElement";
import { LinkData } from "../types";

const ViewLinkPage = () => {
  const [clickedView, setClickedView] = useState(false);
  const [data, setData] = useState<LinkData>();
  const docID = window.location.href.split("/")[3];

  const fetchData = async (): Promise<LinkData | undefined> => {
    try {
      return await db
        .collection("activeLinks")
        .doc(docID)
        .get()
        .then((doc) => {
          return doc.data() as LinkData;
        });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  useEffect(() => {
    (async () => {
      setData(await fetchData());
    })();
  }, []);

  const handleViewMessage = () => {
    setClickedView(true);
    let templateParams = {
      to_email: undefined,
      time:
        new Date().toLocaleString() +
        ", " +
        Intl.DateTimeFormat().resolvedOptions().timeZone.replace("_", " ") +
        " timezone",
      message: "Your Privalink was recently opened at",
    };
    db.collection("activeLinks")
      .doc(docID)
      .get()
      .then((doc) => {
        if (doc.exists) {
          templateParams.to_email = doc.data()?.notify;
        }
      })
      .then(() => {
        db.collection("activeLinks")
          .doc(docID)
          .delete()
          .then(() => {
            if (
              templateParams.to_email !== "" &&
              templateParams.to_email !== undefined
            ) {
              emailjs
                .send(
                  "service_matmpqc",
                  "template_jb0dwaj",
                  templateParams,
                  "user_XvdZN1t0s9N6EJ0D07jFO"
                )
                .then(
                  (result) => {
                    console.log(result.text);
                  },
                  (error) => {
                    console.log(error.text);
                  }
                );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  return (
    <div className="view-link">
      <div className="view-container">
        {!clickedView ? (
          <>
            <h1 className="view-title">Message will self-destruct.</h1>
            <h3 className="view-subtitle">
              After viewing the sensitive information, you may not be able to
              access it again.
            </h3>
            <button className="view-button" onClick={handleViewMessage}>
              <p className="view-button-text">View Message</p>
            </button>
          </>
        ) : (
          <>
            {data?.message && (
              <div className="view-message-container">
                <h3 className="view-subtitle">{data.message}</h3>
              </div>
            )}
            {data && data.files.length > 0 && (
              <div className="view-files-container">
                {data.files.map((url) => {
                  return (
                    <FilePreviewElement
                      name={url
                        .split("/")[7]
                        .split("?")[0]
                        .replaceAll("%20", " ")}
                      viewingMode={true}
                      url={url}
                    />
                  );
                })}
              </div>
            )}
            <button
              className="view-button"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <p className="view-button-text">Back to Home</p>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewLinkPage;

import React, { useEffect, useState } from "react";
import "./GenerateLinkPage.css";
import { db, storage } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import uploadIcon from "../assets/upload-icon.png";
import FilePreviewElement from "../components/FilePreviewElement";
import LoadingBar from "react-top-loading-bar";
import Checkbox from "../components/Checkbox";
import firebase from "firebase";

const GenerateLinkPage = () => {
  const [inputMessage, setInputMessage] = useState<string>("");
  const [newLink, setNewLink] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [notify, setNotify] = useState(false);
  const [notifyEmail, setNotifyEmail] = useState<string>("");
  const [neverExpire, setNeverExpire] = useState(true);
  const [expireDate, setExpireDate] = useState<Date | null>(null);

  const generateLink = async () => {
    if (inputMessage.trim().length > 0 || uploadedFiles.length > 0) {
      setLoading(true);
      let expireDateServerTime: any = undefined;
      if (expireDate) {
        expireDateServerTime = firebase.firestore.Timestamp.fromDate(
          new Date(expireDate)
        );
      }
      if (uploadedFiles.length > 0) {
        // upload all files from uploadedFiles array to firebase storage
        const uploadedFileUrls: string[] = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
          const file = uploadedFiles[i];
          const fileName = file.name;
          const fileRef = storage.ref(fileName);
          const uploadTask = fileRef.put(file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // progress function
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress((progress + i * 100) / uploadedFiles.length);
            },
            (error) => {
              // error function
              console.log(error);
              setLoading(false);
            },
            () => {
              // complete function
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                uploadedFileUrls.push(downloadURL);
                if (uploadedFileUrls.length === uploadedFiles.length) {
                  // if all files are uploaded, generate link
                  db.collection("activeLinks")
                    .add({
                      message: inputMessage,
                      files: uploadedFileUrls,
                      expireDate: expireDateServerTime
                        ? expireDateServerTime
                        : null,
                      notify: notifyEmail,
                      dateCreated:
                        firebase.firestore.FieldValue.serverTimestamp(),
                    })
                    .then(function (docRef) {
                      setNewLink(docRef.id);
                      setLoading(false);
                    });
                }
              });
            }
          );
        }
      } else {
        db.collection("activeLinks")
          .add({
            message: inputMessage,
            files: [],
            expireDate: expireDateServerTime ? expireDateServerTime : null,
            notify: notifyEmail,
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(function (docRef) {
            setNewLink(docRef.id);
            setLoading(false);
          });
      }
    }
    setInputMessage("");
    setUploadedFiles([]);
  };

  const handleFileSelected = (files: FileList | null) => {
    if (!files) return;
    // only add files that are not already in the array
    const newFiles = Array.from(files).filter((file) => {
      return !uploadedFiles.some((uploadedFile) => {
        return uploadedFile.name === file.name;
      });
    });
    setUploadedFiles([...uploadedFiles, ...newFiles]);
  };

  const isDataValid = () => {
    if (inputMessage.trim().length === 0 && uploadedFiles.length === 0) {
      return false;
    }
    if (notify) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        !re.test(String(notifyEmail).toLowerCase()) ||
        notifyEmail.trim().length === 0
      ) {
        return false;
      }
    }
    if (!neverExpire && expireDate) {
      if (expireDate < new Date()) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (!notify) {
      setNotifyEmail("");
    }
    if (neverExpire) {
      setExpireDate(null);
    }
  }, [notify, neverExpire]);

  const copyToClipboard = () => {
    toast("Link copied to clipboard!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="generate">
      {newLink === "" ? (
        <div className="generate-container">
          <h1 className="generate-title">
            Securely send sensitive information.
          </h1>
          <h3 className="generate-subtitle">
            Privalink allows you to send any sensitive data or information to
            people you trust using self-destructing links. After someone has
            viewed the link, all sensitive data will be deleted and the link
            will no longer be available for use.
          </h3>
          <div className="generate-input-container">
            <div className="generate-textarea-container">
              <textarea
                required
                onChange={(text) => {
                  setInputMessage(text.target.value);
                }}
                className="generate-message-input"
                placeholder="Enter your message here"
                value={inputMessage}
              />
              <label htmlFor="file-upload" className="generate-file-upload-btn">
                <img
                  src={uploadIcon}
                  className="generate-upload-icon"
                  alt={"upload-file-icon"}
                />
              </label>
              <input
                id="file-upload"
                type="file"
                className="generate-upload-input"
                multiple
                value=""
                onChange={(e) => handleFileSelected(e.target.files)}
              />
            </div>
            <div className="file-preview-conatiner">
              {uploadedFiles.map((file) => (
                <FilePreviewElement
                  key={file.name + Math.floor(1000 + Math.random() * 9000)}
                  viewingMode={false}
                  name={file.name}
                  setUploadedFiles={setUploadedFiles}
                  uploadedFiles={uploadedFiles}
                />
              ))}
            </div>
            <LoadingBar
              progress={progress}
              className="loading-bar"
              color="linear-gradient(101deg, #79c0ff, #84edc1)"
              onLoaderFinished={() => setProgress(0)}
            />
            <div className="generate-options-container">
              <div className="generate-option">
                <Checkbox
                  label="Notify me when link expires or is opened"
                  checked={notify}
                  setChecked={setNotify}
                />
                <input
                  className="generate-options-input"
                  type="email"
                  placeholder="Your email address"
                  id="generate-notify-email"
                  style={
                    notify
                      ? {
                          maxHeight: "100px",
                          padding: "12px 18px",
                          marginBottom: "30px",
                        }
                      : {}
                  }
                  value={notifyEmail}
                  onChange={(e) => setNotifyEmail(e.target.value)}
                />
              </div>
              <div className="generate-option" id="expiry">
                <Checkbox
                  label="Set to never expire"
                  checked={neverExpire}
                  setChecked={setNeverExpire}
                />
                <input
                  id="generate-expiry-date"
                  className="generate-options-input"
                  type="datetime-local"
                  style={
                    !neverExpire
                      ? {
                          maxHeight: "100px",
                          padding: "10px 18px",
                          marginBottom: "30px",
                        }
                      : {}
                  }
                  value={expireDate ? expireDate.toISOString() : ""}
                  // min={calcMinDate()} TODO: Fix this
                  onChange={(e) => setExpireDate(e.target.valueAsDate)}
                />
              </div>
            </div>
            <button
              onClick={generateLink}
              className={`generate-button-${!isDataValid() || loading}`}
              disabled={!isDataValid() || loading}
            >
              <p className="generate-button-text">Generate Link</p>
            </button>
          </div>
        </div>
      ) : (
        <div className="generate-container">
          <h1 className="generate-title">Link Successfully Generated!</h1>
          <h3 className="generate-subtitle">
            Share the following URL to the people you trust. The link will
            automatically self-destruct after they have viewed the message.
          </h3>
          <CopyToClipboard
            text={window.location.href + newLink}
            onCopy={copyToClipboard}
          >
            <div className="generated-link-container">
              <h1 className="generated-link-text">
                {window.location.href + newLink}
              </h1>
            </div>
          </CopyToClipboard>
          <button
            onClick={() => {
              setNewLink("");
            }}
            className={`generate-button-false`}
          >
            <p className="generate-button-text">Generate Another Link</p>
          </button>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            toastClassName="toast-container"
            progressClassName="toast-progress"
            bodyClassName="toast-body"
            theme="dark"
          />
        </div>
      )}
    </div>
  );
};

export default GenerateLinkPage;

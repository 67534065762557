import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

type NavbarProps = {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const Navbar = (props: NavbarProps) => {
  return (
    <>
      <div className={`navbar${props.active ? "-active" : ""}`}>
        <div
          className="logo"
          onClick={() => {
            window.location.href = "/"; // TODO: Check if this works
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="privalink-logo"
            className="logo-img"
          />
          <h1 className="logo-text">{"Privalink"}</h1>
        </div>
        <div className="links">
          <ul>
            <li>
              <Link to={"/"}>Generate</Link>
            </li>
            <li>
              <Link to={"/security"}>Security</Link>
            </li>
            <li>
              <Link to={"/about"}>About</Link>
            </li>
          </ul>
        </div>
        <div
          className={`hamburger-menu${props.active ? "-active" : ""}`}
          onClick={() => props.setActive(!props.active)}
        >
          <span className="bar1"></span>
          <span className="bar2"></span>
          <span className="bar3"></span>
        </div>
      </div>
      <div className={`side-menu${props.active ? "-active" : ""}`}>
        <div className="links-active">
          <ul>
            <li>
              <Link to={"/"} onClick={() => props.setActive(false)}>
                Generate
              </Link>
            </li>
            <li>
              <Link to={"/security"} onClick={() => props.setActive(false)}>
                Security
              </Link>
            </li>
            <li>
              <Link to={"/about"} onClick={() => props.setActive(false)}>
                About
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;

import React from "react";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about">
      <div className="about-container">
        <h1 className="about-title">About Privalink</h1>
        <h3 className="about-subtitle">
          Sensitive data circulates the internet in a vast amount, sometimes
          completely susceptible to exposure. People are often unaware of the
          potential risks of sharing sensitive data using services like email or
          text messaging.
        </h3>
        <h3 className="about-subtitle">
          Privalink is a tool that allows users send and receive sensitive data
          in a safe and secure way. In an easy to use interface, users simply
          input their message and click a button to generate their secure link.
        </h3>
      </div>
      <h3 className="created-by-text">Created by Krushay Bhavsar 2021</h3>
    </div>
  );
};

export default AboutPage;
